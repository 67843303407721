<template name="component-name">
  <div class="single-input-componnet" :style="boxStyle">
    <span class="si-component-title" :style="titleStyle">{{ title }}</span>
    <a-input
      :class="'si-component-input ' + inputSuffix"
      :placeholder="placeholder"
      :style="inputStyle"
      :disabled="disabled"
      :value="value"
      @change="inputChange"
    >
      <template>
        <span slot="suffix" v-if="suffix" :style="suffixStyle">{{suffix}}</span>
      </template>
    </a-input>
    <a-tooltip :placement="placement">
      <template slot="title">{{ tooltip }}</template>
      <a-icon type="question-circle" class="si-component-tooltip" v-show="tooltip" />
    </a-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    placement: {
      type: String,
      default: "top"
    },
    inputSuffix: {
      type: String,
      default: ""
    },
    suffixStyle: {
      type: String,
      default: ""
    },
    suffix: {
      type: String | Number,
      default: ""
    },
    boxStyle: {
      type: String,
      default: ""
    },
    titleStyle: {
      type: String,
      default: ""
    },
    inputStyle: {
      type: String,
      default: ""
    },
    inputName: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String | Number,
      default: ""
    }
  },
  data() {
    return {};
  },
  mounted() {},
  components: {},
  methods: {
    inputChange(e) {
      this.$emit("inputChange", e.target.value, this.inputName);
    }
  }
};
</script>
<style lang="less">
@import "./../../style/inputComponent.less";
</style>
