// 项目中各种文件icon
export const fileType = {
  gif: "iconimage",
  svg: "iconimage",
  jpg: "iconimage",
  jpeg: "iconimage",
  bmp: "iconimage",
  png: "iconimage",
  doc: "iconword",
  docx: "iconword",
  xls: "iconexcl",
  xlsx: "iconexcl",
  ppt: "iconppt",
  pptx: "iconppt",
  pdf: "iconpdf",
  eps: "iconeps",
  txt: "icontxt",
  csv: "icontxt",
  tsv: "icontxt",
  wmv: "iconmp4",
  mov: "iconmp4",
  mp4: "iconmp4",
  avi: "iconmp4",
  flv: "iconmp4",
  rm: "iconmp4",
  htm: "iconhtml",
  html: "iconhtml",
  zip: "iconzip",
  rar: "iconzip",
  bz2: "iconzip",
  gz: "iconzip",
  tar: "iconzip",
  fastq: "iconfq",
  fq: "iconfq",
  directory: "iconfile",
  unknown: "iconunknown"
};

// export const fileTypeObj = {
//   gif: "img-icon",
//   svg: "img-icon",
//   jpg: "img-icon",
//   jpeg: "img-icon",
//   bmp: "img-icon",
//   png: "img-icon",
//   doc: "doc-icon",
//   docx: "doc-icon",
//   xls: "xls-icon",
//   xlsx: "xls-icon",
//   ppt: "ppt-icon",
//   pptx: "ppt-icon",
//   pdf: "pdf-icon",
//   eps: "eps-icon",
//   txt: "txt-icon",
//   csv: "txt-icon",
//   tsv: "txt-icon",
//   wmv: "video-icon",
//   mov: "video-icon",
//   mp4: "video-icon",
//   avi: "video-icon",
//   flv: "video-icon",
//   rm: "video-icon",
//   htm: "html-icon",
//   html: "html-icon",
//   zip: "compress-icon",
//   rar: "compress-icon",
//   bz2: "compress-icon",
//   gz: "compress-icon",
//   tar: "compress-icon",
//   fastq: "fq-icon",
//   fq: "fq-icon",
//   directory: "directory-icon",
//   unknown: "unknown-icon"
// };
