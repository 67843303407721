var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-input-component",style:(_vm.boxStyle)},[_c('a-form',{class:'fi-component-form' + (!_vm.error ? ' fi-component-form--error' : ''),attrs:{"form":_vm.form,"layout":_vm.layout}},[_c('a-form-item',{staticClass:"fic-form-item",attrs:{"label":_vm.label,"colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.decoratorId,
          {
            initialValue: _vm.initialValue,
            rules: [
              {
                validator: _vm.validator
              }
            ],
            getValueFromEvent: _vm.getValueFrom
          }
        ]),expression:"[\n          decoratorId,\n          {\n            initialValue: initialValue,\n            rules: [\n              {\n                validator: validator\n              }\n            ],\n            getValueFromEvent: getValueFrom\n          }\n        ]"}],staticClass:"ficf-item-input",style:(_vm.inputStyle),attrs:{"name":_vm.name,"placeholder":_vm.placeholder}},[[(_vm.suffix)?_c('span',{style:(_vm.suffixStyle),attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v(_vm._s(_vm.suffix))]):_vm._e()]],2),_c('a-tooltip',{attrs:{"placement":_vm.placement}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.tooltip))]),_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"ficf-item-tooltip",attrs:{"type":"question-circle"}})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }