<template name="component-name">
  <div class="form-input-component" :style="boxStyle">
    <a-form
      :form="form"
      :layout="layout"
      :class="'fi-component-form' + (!error ? ' fi-component-form--error' : '')"
    >
      <a-form-item :label="label" class="fic-form-item" :colon="false">
        <a-input
          :name="name"
          :placeholder="placeholder"
          class="ficf-item-input"
          :style="inputStyle"
          v-decorator="[
            decoratorId,
            {
              initialValue: initialValue,
              rules: [
                {
                  validator: validator
                }
              ],
              getValueFromEvent: getValueFrom
            }
          ]"
        >
          <template>
            <span slot="suffix" v-if="suffix" :style="suffixStyle">{{suffix}}</span>
          </template>
        </a-input>
        <a-tooltip :placement="placement">
          <template slot="title">{{ tooltip }}</template>
          <a-icon type="question-circle" class="ficf-item-tooltip" v-show="tooltip" />
        </a-tooltip>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
export default {
  props: {
    placement: {
      type: String,
      default: "top"
    },
    tooltip: {
      type: String | Number,
      default: ""
    },
    suffixStyle: {
      type: String,
      default: ""
    },
    suffix: {
      type: String | Number,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    decoratorId: {
      type: String,
      default: "input"
    },
    boxStyle: {
      type: String,
      default: ""
    },
    inputStyle: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "'"
    },
    placeholder: {
      type: String,
      default: ""
    },
    initialValue: {
      type: String | Number,
      default: ""
    },
    validator: {
      type: Function,
      default: function (rule, value, cb) {}
    },
    getValueFrom: {
      type: Function,
      default: function (e) {}
    }
  },
  data() {
    return {
      layout: "inline",
      form: this.$form.createForm(this)
    };
  },
  methods: {
    // validatorFn(rule, value, callback) {
    //   callback("");
    // },
    // getValueFrom(e) {
    //   console.log(e);
    //   // return e.target.value;
    // }
  }
};
</script>
<style lang="less">
@import "./../../style/formInputComponent.less";
</style>
